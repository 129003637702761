export enum REREmployeeStatus {
    NEW = "NEW",
    OPENED_BY_EMPLOYEE = "OPENED_BY_EMPLOYEE",
    DOCUMENTS_PARTIALLY_UPLOADED = "DOCUMENTS_PARTIALLY_UPLOADED",
    ALL_DOCUMENTS_UPLOADED = "ALL_DOCUMENTS_UPLOADED",
    I9_PREPARED = "I9_PREPARED",
    I9_BEING_SIGNED = "I9_BEING_SIGNED",
    I9_SIGNED = "I9_SIGNED",
    COMPLETED = "COMPLETED",
    FILLED_BY_CUSTOMER = "FILLED_BY_CUSTOMER",
}
