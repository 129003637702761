
































































































import {Vue, Component} from 'vue-property-decorator';
import CompanySearch from "@/components/common/CompanySearch.vue";
import {CreateREREmployeePayload} from "@/dto/request/rer/CreateRERPayload";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import {namespace} from "vuex-class";
import RERService from "@/services/request/RERService";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import SelectOption from "@/components/common/SelectOption";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import BranchDto from "@/components/profile/branches/BranchDtos";
import AddressDTO from "@/dto/AddressDTO";
import {RER_DTO} from "@/dto/request/rer/RER_DTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import Notifications from "@/state/Notifications";
import moment from "moment/moment";
import { REREmployeeStatus } from "@/constants/request/RERConstants";

const AppModule = namespace("App");

@Component<CreateRERCustomerMode>({
  components: {
    PortalSelect,
    PortalDate,
    SimpleCompanySearch,
    PortalCheckbox,
    PhoneInput,
    PortalInput,
    PortalCollapse,
    CompanySearch
  },
  computed: {
    PayPeriodType() {
      return PayPeriodType;
    },
    PayType() {
      return PayType;
    },
    SelectOption() {
      return SelectOption;
    },
    REREmployeeStatus() {
      return REREmployeeStatus;
    },
  }
})
export default class CreateRERCustomerMode extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private newEmployee = new CreateREREmployeePayload();
  private company: RER_DTO | null = null
  private isCompanyLoaded = false;
  private addMode = false;
  private branches: BranchDto[] = [];
  private expandedEmployeeId: null | number = null;

  public mounted(): void {
    this.getCurrentCompany();
  }

  private getCurrentCompany(): void {
    RERService.getByPublicId(this.id).then(
      response => {
        this.company = response.data;
        this.branches = this.company?.__branches as BranchDto[];
        this.isCompanyLoaded = true;
        if (this.company.employees?.length === 0 && this.company.status !== REREmployeeStatus.FILLED_BY_CUSTOMER) {
          this.addMode = true;
        }
      })
  }

  private buildPayPeriodTypes(): Array<SelectOption> {
    let payPeriodTypes: Array<SelectOption> = [];
    const periodTypes = Object.values(PayPeriodType);
    periodTypes.forEach(period => {
      payPeriodTypes.push(
          SelectOption.builder()
              .title(`${period}`)
              .value(period)
              .build()
      )
    })
    return payPeriodTypes;
  }

  private buildPayTypes(): Array<SelectOption> {
    let payTypes: Array<SelectOption> = [];
    const types = Object.values(PayType);
    types.forEach(type => {
      payTypes.push(
          SelectOption.builder()
              .title(`${type}`)
              .value(type)
              .build()
      )
    })
    return payTypes;
  }

  private buildWorkplaces(): Array<SelectOption> {
    let workplaces: Array<SelectOption> = [];
    this.newEmployee.branchesId
        .map(id => this.branches.find(it => it.id == id))
        .map(it => this.assembleAddresses(it!))
        .map(list => list.filter(address => !!address && !!address.fullAddress).map(address => SelectOption.builder().title(address!.fullAddress).value(address!.id).build()))
        .forEach(list => workplaces.push(...list))
    return workplaces;
  }

  private assembleAddresses(branch: BranchDto) {
    let res = [];
    if (branch.legalAddress && branch.legalAddress.fullAddress) {
      res.push(branch.legalAddress)
    }
    if (branch.actualAddress && branch.actualAddress.fullAddress) {
      res.push(branch.actualAddress)
    }
    if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
      res.push(branch.mailingAddress)
    }
    if (!AddressDTO.isEmpty(branch.registrationAgentAddress)) {
      res.push(branch.registrationAgentAddress)
    }
    return res;
  }

  private getBranchTitleAndAddressById(addressId: number) {
    for (const branch of this.company?.__branches as BranchDto[]) {
      const addresses = [
        branch.legalAddress,
        branch.mailingAddress,
        branch.actualAddress,
        branch.registrationAgentAddress
      ];

      for (const address of addresses) {
        if (address && address.id === addressId) {
          return {
            title: branch.title,
            address: address.fullAddress
          };
        }
      }
    }
    return { title: 'Not Found', address: 'Not Found' };
  }

  private addEmployee() {
    ifValid(this, () => {
      this.startLoading()
      RERService.addEmployee(this.newEmployee, this.id).then(
        ok => {
          this.stopLoading();
          this.newEmployee = new CreateREREmployeePayload();
          Notifications.success("A new employee has been successfully added");
          this.getCurrentCompany();
          this.addMode = false;
        },
        err => {
          this.stopLoading();
          processError(this, err);
        }
      )
    }, () => {
      Notifications.error("Please fill up all forms required");
    }
    )
  }

  private changeMode() {
    this.addMode = !this.addMode;
  }

  private toggleDetails(id: number) {
    if (this.expandedEmployeeId === id) {
      this.expandedEmployeeId = null;
    } else {
      this.expandedEmployeeId = id;
    }
  }

  completeFillingEmployees(): void {
    RERService.completeFillingEmployees(this.id).then(
      ok => {
        this.getCurrentCompany();
        this.stopLoading();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  get id(): string {
    return this.$route.params.id;
  }

  get getMinValidDate(): string {
    const fiveDays = moment.duration(5, 'd');
    const today = moment();
    return today.subtract(fiveDays).format('YYYY-MM-DD');
  }

  get getMaxValidDate(): string {
    return moment().format('YYYY-MM-DD');
  }

  get isHireDateValid(): boolean {
    const hireDate = moment(this.newEmployee.employmentStart);
    const today = moment();
    const sixDaysAgo = moment().subtract(moment.duration(6, 'd'));
    return !this.newEmployee.employmentStart || hireDate.isBetween(sixDaysAgo, today, null, "[]");
  }

}
